import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {FunctionalTranspilerWithParams, NestedArgumentFallback, PaginatorIntl} from '@followme/shared/util-core';
import {AuthenticationModule, clearState} from '@forlabs/api-bridge';
import {provideTranslocoTranspiler} from '@jsverse/transloco';
import {
  DefaultDataServiceConfig,
  EntityCollectionReducerMethodsFactory,
  EntityDataModule,
  EntityDataService,
} from '@ngrx/data';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ngrxEntityRelationshipReducer} from 'ngrx-entity-relationship';
import {MercureEffects} from './+state/_mercure/effects';
import {MERCURE_FEATURE_KEY, mercureReducer} from './+state/_mercure/reducer';
import {MetaEffects} from './+state/_meta/effects';
import {ForlabsEffects, ForlabsEntityCollectionReducerMethodsFactory} from './+state/articles/articles.metadata';
import {Article} from './+state/articles/articles.models';
import {ArticleCollectionService, ArticleDataService} from './+state/articles/articles.ngrx-data';
import {ArticleSelectorService} from './+state/articles/articles.selectors';
import {CourseMessage} from './+state/course-messages/course-messages.models';
import {
  CourseMessageCollectionService,
  CourseMessageDataService,
} from './+state/course-messages/course-messages.ngrx-data';
import {CourseMessageSelectorService} from './+state/course-messages/course-messages.selectors';
import {Info} from './+state/infos/infos.models';
import {InfoCollectionService, InfoDataService} from './+state/infos/infos.ngrx-data';
import {InfoSelectorService} from './+state/infos/infos.selectors';
import {defaultDataServiceConfig, entityMetadata, pluralNames} from './+state/metadata';
import {Organization} from './+state/organizations/organizations.models';
import {OrganizationCollectionService, OrganizationDataService} from './+state/organizations/organizations.ngrx-data';
import {OrganizationSelectorService} from './+state/organizations/organizations.selectors';
import {PatientStep} from './+state/patient-steps/patient-steps.models';
import {PatientStepCollectionService, PatientStepDataService} from './+state/patient-steps/patient-steps.ngrx-data';
import {PatientStepSelectorService} from './+state/patient-steps/patient-steps.selectors';
import {Contact, HealthPro, Patient} from './+state/users/users.models';
import {
  ContactCollectionService,
  ContactDataService,
  HealthProCollectionService,
  HealthProDataService,
  PatientCollectionService,
  PatientDataService,
} from './+state/users/users.ngrx-data';
import {ContactSelectorService, HealthProSelectorService, PatientSelectorService} from './+state/users/users.selectors';
import {WorkflowButton} from './+state/workflow-buttons/workflow-buttons.models';
import {
  WorkflowButtonCollectionService,
  WorkflowButtonDataService,
} from './+state/workflow-buttons/workflow-buttons.ngrx-data';
import {WorkflowButtonSelectorService} from './+state/workflow-buttons/workflow-buttons.selectors';
import {Workflow} from './+state/workflows/workflows.models';
import {WorkflowCollectionService, WorkflowDataService} from './+state/workflows/workflows.ngrx-data';
import {WorkflowSelectorService} from './+state/workflows/workflows.selectors';
import {ErrorModule} from './error/error.module';
import {TranslocoRootModule} from './translations/transloco-root.module';
import {chartsReducer} from './ngrx/charts/charts.reducer';
import {ChartsEffects} from './ngrx/charts/charts.effects';


@NgModule({
  imports: [
    CommonModule,
    ErrorModule,
    TranslocoRootModule,
    AuthenticationModule.forRoot({
      // userEntityConstructors: {Patient, HealthPro, Contact},
      userSelectorServices: {
        [Patient.getEntityName()]: PatientSelectorService,
        [HealthPro.getEntityName()]: HealthProSelectorService,
        [Contact.getEntityName()]: ContactSelectorService,
      },
    }),
    StoreModule.forRoot(
      {
        charts: chartsReducer,
      },
      {
        metaReducers: [
          ngrxEntityRelationshipReducer,
          clearState,
        ],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    StoreModule.forFeature(
      MERCURE_FEATURE_KEY,
      mercureReducer,
    ),
    EffectsModule.forFeature([MercureEffects, ForlabsEffects]),
    EffectsModule.forRoot([MetaEffects, ChartsEffects]),
    EntityDataModule.forRoot({
      entityMetadata,
      pluralNames,
    }),
  ],
  providers: [
    ArticleCollectionService,
    ArticleDataService,
    ArticleSelectorService,
    CourseMessageCollectionService,
    CourseMessageDataService,
    CourseMessageSelectorService,
    InfoCollectionService,
    InfoDataService,
    InfoSelectorService,
    OrganizationCollectionService,
    OrganizationDataService,
    OrganizationSelectorService,
    PatientStepCollectionService,
    PatientStepDataService,
    PatientStepSelectorService,
    ContactCollectionService,
    ContactDataService,
    ContactSelectorService,
    HealthProCollectionService,
    HealthProDataService,
    HealthProSelectorService,
    PatientCollectionService,
    PatientDataService,
    PatientSelectorService,
    WorkflowCollectionService,
    WorkflowDataService,
    WorkflowSelectorService,
    WorkflowButtonCollectionService,
    WorkflowButtonDataService,
    WorkflowButtonSelectorService,
    {provide: MatPaginatorIntl, useClass: PaginatorIntl},
    {provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig},
    {provide: EntityCollectionReducerMethodsFactory, useClass: ForlabsEntityCollectionReducerMethodsFactory},
    // provideTranslocoScope('additionalData'),
    provideTranslocoTranspiler(FunctionalTranspilerWithParams),
    {
      provide: 'nestedArgumentFallback', // ====> The function name used in the translation
      useClass: NestedArgumentFallback,
    },
  ],
})
export class DomainModule {
  constructor(
    entityDataService: EntityDataService,
    articleDataService: ArticleDataService,
    courseMessageDataService: CourseMessageDataService,
    infoDataService: InfoDataService,
    organizationDataService: OrganizationDataService,
    patientStepDataService: PatientStepDataService,
    contactDataService: ContactDataService,
    healthProDataService: HealthProDataService,
    patientDataService: PatientDataService,
    workflowDataService: WorkflowDataService,
    workflowButtonDataService: WorkflowButtonDataService,
  ) {
    entityDataService.registerService(Article.getEntityName(), articleDataService);
    entityDataService.registerService(CourseMessage.getEntityName(), courseMessageDataService);
    entityDataService.registerService(Info.getEntityName(), infoDataService);
    entityDataService.registerService(Organization.getEntityName(), organizationDataService);
    entityDataService.registerService(PatientStep.getEntityName(), patientStepDataService);
    entityDataService.registerService(Contact.getEntityName(), contactDataService);
    entityDataService.registerService(HealthPro.getEntityName(), healthProDataService);
    entityDataService.registerService(Patient.getEntityName(), patientDataService);
    entityDataService.registerService(WorkflowButton.getEntityName(), workflowButtonDataService);
    entityDataService.registerService(Workflow.getEntityName(), workflowDataService);
  }
}


// import {NgModule} from '@angular/core';
// import {CommonModule} from '@angular/common';
// import {StoreModule} from '@ngrx/store';
// import {EffectsModule} from '@ngrx/effects';
// import {AdvisorsEffects} from './+state/advisors/advisor.effects';
// import * as fromAdvisors from './+state/advisors/advisor.reducer';
// import {GeneralContentsEffects} from './+state/general-contents/general-contents.effects';
// import * as fromGeneralContents from './+state/general-contents/general-contents.reducer';
// import {PatientContactsEffects} from './+state/patient-contacts/patient-contacts.effects';
// import * as fromPatientContacts from './+state/patient-contacts/patient-contacts.reducer';
//
//
// @NgModule({
//   imports: [
//     CommonModule,
//     StoreModule.forFeature(
//       fromGeneralContents.GENERALCONTENTS_FEATURE_KEY,
//       fromGeneralContents.reducer,
//     ),
//     StoreModule.forFeature(
//       fromPatientContacts.PATIENTCONTACTS_FEATURE_KEY,
//       fromPatientContacts.reducer,
//     ),
//     StoreModule.forFeature(
//       fromAdvisors.ADVISORS_FEATURE_KEY,
//       fromAdvisors.reducer,
//     ),
//     EffectsModule.forFeature([GeneralContentsEffects]),
//     EffectsModule.forFeature([PatientContactsEffects]),
//     EffectsModule.forFeature([AdvisorsEffects]),
//   ],
// })
// export class GeneralContentsDomainModule {}
